<template>
  <p class="title-p">公众号关键字回复</p>
  <div class="ctent">
    <div class="title-tool department-tool">

      <div style="display: flex;width: 70%;">
        <div class="tool-item">
          <div class="tool-label">
            搜索：
          </div>
          <el-input v-model="search" placeholder="输入回复关键字" style="width: 100%;"/>
        </div>

        <div class="tool-item" style="color: #ffffff;">
          <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </div>
      </div>
      <div class="tool-item" style="width: auto;margin-right: 0">
        <el-button type="primary" plain @click="addReply">新增</el-button>
      </div>

    </div>

    <div class="department-table">
      <el-table :data="replyData" style="width: 100%" :row-style="{height:'65px'}">
        <el-table-column prop="receive" label="关键字" align="center" show-overflow-tooltip/>
        <el-table-column prop="type" label="回复类型" align="center">
          <template #default="scope">
            <el-tag class="ml-2" type="info" v-if="scope.row.type === '1' ">文字</el-tag>
            <el-tag v-else-if="scope.row.type === '2' ">图片</el-tag>
            <el-tag class="ml-2" type="info" v-else>文章</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="发布时间" align="center"/>
        <el-table-column label="操作">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <button class="el-button el-button--text el-button--small" type="button"
                      @click="showDepartment(scope.row)" >
                <span style="color: #409eff;">编辑</span>
              </button>
               <el-popconfirm title="确定要删除吗?" @confirm="delDepartment(scope.row)">
                 <template #reference>
                   <button class="el-button el-button--text el-button--small" type="button">
                     <span style="color: #f56c6c;">删除</span>
                   </button>
                 </template>
               </el-popconfirm>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <el-pagination
          v-model:currentPage="nowPage"
          v-model:page-size="pageLimit"
          layout="total,prev, pager, next, jumper"
          :total="tableTotal"
          @current-change="handleCurrentChange"
      />
    </div>

  </div>
  <!--  新增-->
  <el-drawer v-model="visibleReply" :show-close="false" size="40%" @closed="visibleReplyClosed">
    <template #header="{ close, titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" style="text-align: left">{{ visibleTitle }}</h4>
      <el-button type="danger" @click="close">
        <el-icon class="el-icon--left">
          <CircleCloseFilled/>
        </el-icon>
        Close
      </el-button>
    </template>
    <el-form :model="formReply" label-width="100px">

      <el-form-item label="触发关键字">
        <el-input v-model="formReply.receive"/>
      </el-form-item>

      <el-form-item label="回复类型">
        <el-radio-group v-model="formReply.type">
          <el-radio label="1">文字</el-radio>
          <el-radio label="2">图片</el-radio>
          <el-radio label="3">文章</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="回复内容" v-if="formReply.type === '1'">
        <el-input v-model="formReply.reply" type="textarea" :autosize="{ minRows: 2 }"/>
      </el-form-item>

      <el-form-item v-else-if="formReply.type === '2'">
        <el-image
            style="width: 200px; height: 200px;margin-right: 15px"
            :src="materialSrcList[0]"
            :zoom-rate="1.2"
            :preview-src-list="materialSrcList"
            fit="cover"
        >
          <template #error>
            <div class="image-slot-err">
              <el-icon><Picture /></el-icon>
            </div>

          </template>
        </el-image>
        <el-button type="primary" @click="materialLibrary = true">选择图片</el-button>
      </el-form-item>

      <el-form-item label="封面" v-else-if="formReply.type === '3'">
        <el-image
            style="width: 200px; height: 200px;margin-right: 15px"
            :src="articleSrcList[0]"
            :zoom-rate="1.2"
            :preview-src-list="articleSrcList"
            fit="cover"
        >
          <template #error>
            <div class="image-slot-err">
              <el-icon><Picture /></el-icon>
            </div>

          </template>
        </el-image>
        <el-button type="primary" @click="selectArticle = true">选择文章</el-button>
      </el-form-item>

      <el-form-item label="标题" v-if="formReply.type === '3'">
        <el-input v-model="nowCheckCg.title" disabled />
      </el-form-item>

      <el-form-item label="摘要" v-if="formReply.type === '3'">
        <el-input v-model="nowCheckCg.digest" type="textarea" :autosize="{ minRows: 2 }" disabled />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="visibleReply = false">取消</el-button>
      </el-form-item>


    </el-form>
  </el-drawer>

<!--  素材库 materialLibrary-->
  <el-dialog v-model="materialLibrary" title="素材库" width="60%" top="2vh">

    <div class="cent">
      <div class="cent-box" style="position: relative">


        <el-row class="sc-cent">
          <div class="cg-card-item card-item"
               style="width: 16.66%;padding: 12px"
               v-for="(item,index) in scFileList"
               :key="index"
          >
            <div class="cg-card-cent" :style="{boxShadow: `var(--el-box-shadow-lighter)`}">
              <!--                 素材库多选按钮-->
              <div class="sc-check"
                   :class="{'is-checked':nowCheckSc.media_id === item.media_id}"
                   @click="checkSc(item)">
                <span class="el-checkbox__inner"></span>
              </div>

              <el-image style="width: 100%;height: 7vw;padding: 5px;box-sizing: border-box"
                        :src="item.url" fit="scale-down" >
                <template #error>
                  <div class="image-slot">
                    暂无封面
                    <!--                        <el-icon><iconPicture /></el-icon>-->
                  </div>
                </template>
              </el-image>
              <p class="demonstration" :title="item.file_name">{{item.file_name}}</p>
            </div>
          </div>
        </el-row>

      </div>

      <!--            分页-->
      <div class="pagination-box-sc">
        <el-pagination
            v-model:current-page="currentPageSc"
            v-model:page-size="pageLimitSc"
            layout="total,prev, pager, next, jumper"
            :total="ScCurrenttotal"
            @current-change="handleCurrentChangeSc"
        />
      </div>

    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="materialLibrary = false">取消</el-button>
        <el-button type="primary" @click="materialSub">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!--  文章库-->
  <el-dialog v-model="selectArticle" title="文章库" width="60%" top="2vh">

    <div class="cent">
      <div class="cent-box" style="position: relative">

        <el-row class="sc-cent">
          <div class="cg-card-item card-item"
               v-for="(item,index) in draftList"
               :key="index"
          >
            <div class="cg-card-cent" :style="{boxShadow: `var(--el-box-shadow-lighter)`}">
              <!--                 素材库多选按钮-->
              <div class="sc-check"
                   :class="{'is-checked':nowCheckCg.media_id === item.media_id && nowCheckCg.article_index === item.article_index}"
                   @click="checkCg(item)">
                <span class="el-checkbox__inner"></span>
              </div>

              <el-image style="width: 100%;height: 7vw;padding: 5px;box-sizing: border-box"
                        :src="item.thumb_media_url" fit="scale-down" >
                <template #error>
                  <div class="image-slot">
                    暂无封面
                    <!--                        <el-icon><iconPicture /></el-icon>-->
                  </div>
                </template>
              </el-image>
              <p class="demonstration" :title="item.title">{{item.title}}</p>
            </div>
          </div>
        </el-row>

      </div>

      <!--            分页-->
      <div class="pagination-box-sc">
        <el-pagination
            v-model:current-page="currentPageCg"
            v-model:page-size="pageLimitCg"
            layout="total,prev, pager, next, jumper"
            :total="CgCurrenttotal"
            @current-change="handleCurrentChangeCg"
        />
      </div>

    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="selectArticle = false">取消</el-button>
        <el-button type="primary" @click="articleSub">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "automaticReply",
  data() {
    return {
      selectArticle:false,
      materialLibrary:false,
      formReply: {
        receive: '',
        type: '1',
        reply: ''
      },
      search: '',
      isAddReply: false,
      visibleReply: false,
      visibleTitle: '新增关键字回复',
      replyData:[],
      nowPage: 1,
      pageLimit: 8,
      tableTotal: 0,
      currentPageCg:1,
      currentPageSc:1,
      CgCurrenttotal:0,
      ScCurrenttotal:0,
      pageLimitCg:12,
      pageLimitSc:18,
      scFileList:[],
      nowCheckSc:{},
      nowCheckCg:{},
      materialSrcList:[],
      articleSrcList:[],
      draftList:[],
      nowEditItem:{}

    }
  },
  methods: {
    handleCurrentChangeCg(){
      this.getDraftList();
    },
    materialSub(){
      this.materialSrcList = [];
      this.materialLibrary = false;
      if(this.nowCheckSc.url){
        this.materialSrcList.push(this.nowCheckSc.url);
      }
    },
    articleSub(){
      this.articleSrcList = [];
      this.selectArticle = false;
      if(this.nowCheckCg.thumb_media_url){
        this.articleSrcList.push(this.nowCheckCg.thumb_media_url);
      }
      this.nowCheckCg.title = this.nowCheckCg.title;
      this.nowCheckCg.digest = this.nowCheckCg.digest;
    },
    handleCurrentChangeSc(){
      this.getFileList();
    },
    handleCurrentChange(){
      this.getReplyTableData();
    },
    checkSc(item){
      this.nowCheckSc = item;
    },
    checkCg(item){
      this.nowCheckCg = item;
      console.log(item)
    },
    selectPicture(){

    },
    delDepartment(data){
      vaildInterface("wechat/api.php", "delAutoReply",{
        keyword:'delAutoReply',
        id:data.id
      }, "01").then((res) => {
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        ElMessage.success(res.msg);
        this.getReplyTableData();
      }).catch((result => {
        //数据返回失败
      }));
    },
    showDepartment(item){
      this.nowEditItem = item;
      this.isAddReply = false;
      this.visibleTitle = '编辑关键字回复';
      this.visibleReply = true;
      this.formReply.receive = item.receive;
      this.formReply.type = item.type;
      if(item.type === '1'){
        this.formReply.reply = item.reply;
      }else if(item.type === '2'){
        this.materialSrcList = [item.url];
      }else if(item.type === '3'){
        var reply = JSON.parse(item.reply);
        this.nowCheckCg.title = reply.title;
        this.nowCheckCg.digest = reply.desc;
        this.nowCheckCg.article_index = reply.article_index;
        this.articleSrcList = [reply.thumb_media_url];
      }
    },
    visibleReplyClosed() {
      this.formReply = {
        receive: '',
        type: '1',
        reply: ''
      };
      this.visibleTitle = '新增关键字回复';
      this.isAddReply = false;
      this.materialSrcList = [];
      this.articleSrcList = [];
      this.nowCheckSc = {};
      this.nowCheckCg = {};
      this.nowEditItem = {};
    },
    onSubmit() {
      if(!this.formReply.receive){
        ElMessage.error('触发关键字不能为空');
        return
      }
      var fromData = {
        keyword: 'addAutoReply',
        type: this.formReply.type.toString(),
        receive: this.formReply.receive,
        reply: this.formReply.reply,
      };
      //编辑
      if (!this.isAddReply) {
        fromData.id = this.nowEditItem.id;
      }
      if(this.formReply.type === '2'){
        fromData.reply = this.nowCheckSc.media_id;
      }else if(this.formReply.type === '3'){
        console.log(this.nowCheckCg)
        var replyData = {
          title:this.nowCheckCg.title || '',
          desc:this.nowCheckCg.digest || '',
          picurl:this.nowCheckCg.thumb_media_url_wx || '',
          url:`https://wechat.zcbds.com/wechat/detailsPage.html?md=${this.nowCheckCg.media_id}&art=${this.nowCheckCg.article_index}`,
          thumb_media_url:this.nowCheckCg.thumb_media_url || ''
        }
        fromData.reply = JSON.stringify(replyData);
      }
      vaildInterface("wechat/api.php", "addAutoReply", fromData, "01").then((res) => {
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        ElMessage.success(res.msg);
        this.visibleReply = false;
        this.getReplyTableData();
      }).catch((result => {
        //数据返回失败
      }));
    },
    addReply() {
      this.visibleReply = true;
      this.isAddReply = true;
      this.visibleTitle = '新增关键字回复';
    },
    selectBtn() {
      this.getReplyTableData();
    },
    resetBtn() {
      this.search = '';
    },
    getReplyTableData() {
      vaildInterface("wechat/api.php","getAutoReply",{
        keyword:'getAutoReply',
        limit: this.pageLimit.toString(),
        page: this.nowPage.toString(),
        search:this.search,
      },"01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.data.msg);
          return
        }
        this.replyData = res.data.list;
        this.tableTotal = parseInt(res.data.total);
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //获取素材库列表
    getFileList(){
      requestfaceWithHeadersfn("wechat/index.php","getFileList",{
        keyword:'getFileList',
        limit:this.pageLimitSc.toString(),
        page:this.currentPageSc.toString()
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.scFileList = data.data.item;
          this.ScCurrenttotal = data.data.total_count;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //获取草稿列表
    getDraftList(){
      requestfaceWithHeadersfn("wechat/index.php","articleList",{
        keyword:'articleList',
        limit:this.pageLimitCg.toString(),
        page:this.currentPageCg.toString()
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          this.draftList = data.data.list ;
          this.CgCurrenttotal = data.data.total;
          // console.log(this.leftTreeList)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
  },
  mounted() {
    this.getReplyTableData();
    //获取素材库列表
    this.getFileList();
  //  文章列表
    this.getDraftList();
  }
}
</script>

<style scoped>
.ctent {
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 32px);
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
}

department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 50px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 30px;
  bottom: 25px;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.cent {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}
.cent-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 25px;
}
.demonstration{
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  line-height: 40px;
  text-align: left;
  padding-left: 15px;
  box-sizing: border-box;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.card-item .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 24px;
}
.cg-card-item{
  width: 25%;
  height: 11vw;
  padding: 25px;
  box-sizing: border-box;
}
.cg-card-cent{
  width: 100%;
  position: relative;
  overflow: hidden;
}
.cg-card-cent:hover .cg-hover{
  transform: translateY(0);
}
.cg-card-cent:hover .sc-hover{
  transform: translateY(0);
}
.sc-hover{
  width: 100%;
  height: 25%;
  transition: .5s;
  background: rgba(0,0,0,.3);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(101%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sc-check{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  --el-checkbox-font-size: 14px;
  --el-checkbox-font-weight: var(--el-font-weight-primary);
  --el-checkbox-text-color: var(--el-text-color-regular);
  --el-checkbox-input-height: 14px;
  --el-checkbox-input-width: 14px;
  --el-checkbox-border-radius: var(--el-border-radius-small);
  --el-checkbox-bg-color: var(--el-fill-color-blank);
  --el-checkbox-input-border: var(--el-border);
  --el-checkbox-disabled-border-color: var(--el-border-color);
  --el-checkbox-disabled-input-fill: var(--el-fill-color-light);
  --el-checkbox-disabled-icon-color: var(--el-text-color-placeholder);
  --el-checkbox-disabled-checked-input-fill: var(--el-border-color-extra-light);
  --el-checkbox-disabled-checked-input-border-color: var(--el-border-color);
  --el-checkbox-disabled-checked-icon-color: var(--el-text-color-placeholder);
  --el-checkbox-checked-text-color: var(--el-color-primary);
  --el-checkbox-checked-input-border-color: var(--el-color-primary);
  --el-checkbox-checked-bg-color: var(--el-color-primary);
  --el-checkbox-checked-icon-color: var(--el-color-white);
  --el-checkbox-input-border-color-hover: var(--el-color-primary);
}
.sc-check .el-checkbox__inner{
  width: 100%;
  height: 100%;
  border-radius:2px;
  background-color:#fff;
  cursor: pointer;
}
.el-checkbox__inner:after {
  box-sizing: content-box;
  content: "";
  border: 1px solid var(--el-checkbox-checked-icon-color);
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 6px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform .15s ease-in .05s;
  transform-origin: center;
}
.sc-check.is-checked .el-checkbox__inner:after {
  transform: rotate(45deg) scaleY(1);
}
.sc-check.is-checked .el-checkbox__inner {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
.cg-hover{
  width: 100%;
  height: 30%;
  transition: .5s;
  background: rgba(0,0,0,.3);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-101%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sc-hover .el-icon,.cg-hover .el-icon{
  cursor: pointer;
}
.sc-cent{
  height: calc(100% - 40px);
  align-content: flex-start;
}
.insertLibrary-cent{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  align-content: flex-start;
  margin-bottom: 20px;
}
.pagination-box-sc {
  /*position: absolute;*/
  /*right: 10px;*/
  /*bottom: 15px;*/
  /*z-index: 99;*/
}
.image-slot-err{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
</style>